<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="pub_title_box flex">
      <pubSearch @search="search"/>
      <pubStatus :status_list="status_list" @status_tab="status_tab"/>
    </div>
    <table>
      <thead>
      <tr>
        <td>媒介名称</td>
        <td>价格</td>
        <td>所属平台</td>
        <td>行业类型</td>
        <td>参考粉丝数</td>
        <td>平均浏览量</td>
        <td>周末是否可发</td>
        <td>V认证</td>
        <td>截稿时间</td>
        <!--                    <td>下单备注</td>-->
        <td>状态</td>
        <td>退回原因</td>
        <td>操作</td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in tableData" :key="index">
        <td>
          <!--                        <td_remark :text="item.title" />-->
          {{ item.title }}

        </td>
        <td>￥{{ item.medium_price }}</td>

        <td>{{ item.portal_title }}</td>
        <td>{{ item.industry_classify_title }}</td>
        <td>{{ item.fans_num }}</td>
        <td>{{ item.views }}</td>
        <td :class="item.is_pub_link ? 'td_true' : 'td_false'">{{ item.is_pub_link ? '是' : '否' }}</td>
        <td :class="item.contact ? 'td_true' : 'td_false'">{{ item.contact ? '是' : '否' }}</td>
        <td>
          {{ item.stop_draft_time }}:00
        </td>
        <!-- <td>
            <p class="weight_back">{{ item.m_weight }}</p>
        </td> -->
        <!--                    <td>-->
        <!--                        <td_remark :text="item.remark" />-->

        <!--                    </td>-->
        <td
            :style="{  媒介名称color: item.examine_status == 0 ? '#000' : item.examine_status == 1 && item.status == 0 ? '#00B052' : item.examine_status == 1 && item.status == 0 ? '#00B052' : '#FF0000' }">
          {{ item.examine_status == 0 ? '待审核' : '' }}
          {{ item.examine_status == 1 && item.status == 0 ? '已审核' : '' }}
          {{ item.examine_status == 2 ? '被退回' : '' }}
          {{ item.status == 1 ? '暂停中' : '' }}
        </td>
        <td>{{ item.reason != '' && item.examine_status != 1 ? item.reason : '-' }}
        </td>
        <td>
                        <span style="cursor: pointer; color: #108CDD;margin-right: 1rem;"
                              @click.stop="upDateFn(item)">编辑</span>
          <span style="cursor: pointer; color: #00B052;" v-show="item.status == 1"
                @click="UpClick(item)">申请上架</span>

          <span style="cursor: pointer;color: #FF8400;"
                v-show="item.status == 0 && item.examine_status == 1" @click="reason(item)">暂停</span>
        </td>
      </tr>
      </tbody>
    </table>
    <!-- 分页器 -->
    <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
  </div>
</template>

<script>
import pubStatus from '@/components/pub_media_status'
import pubSearch from '@/components/pubSearch.vue'

export default {
  name: '',
  components: {
    pubStatus,
    pubSearch
  },
  created() {
  },
  mounted() {
    this.listFn()
  },
  data() {
    return {
      title: '',
      fullscreenLoading: false,
      tableData: [],
      total_page: 0, //分页总页数
      count: 0, //总条数
      page: 1,
      status_list: [{
        id: '',
        title: '全部'
      }, {
        id: 0,
        title: '待审核',
        color: '#FF8400'
      }, {
        id: 1,
        title: '已审核',
        color: '#00B052'
      }, {
        id: 2,
        title: '被退回',
        color: '#00B052'
      },
        {
          id: 4,
          title: '暂停中',
          color: '#FF0000'
        }],
    }
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.listFn()
    },
    listFn() {
      this.fullscreenLoading = true
      let data = {}
      data.category_id = 2
      data.page = this.page
      data.limit = 20
      if (this.examine_status !== '') {
        data.examine_status = this.examine_status
      }
      if (this.title != '') {
        data.title = this.title
      }
      this.curlGet('/api/users/medium/list', data).then(res => {
        if (res.data.code) {
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData = res.data.data.list
        }
        this.fullscreenLoading = false

      })
    },
    search(title) {
      this.title = title
      this.listFn()
    },
    status_tab(id) {
      this.examine_status = id
      this.listFn()
    },
    copy() {
      //参数是复制的内容
      //.then()中的函数是复制成功和复制失败的两个回调函数
      this.$copyText(this.status).then(
          (e) => {
            return this.$message({
              message: '复制成功',
              type: 'success',
            })
          },
          (err) => {
            return this.$message({
              message: '复制失败',
              type: 'warning',
            })
          }
      )
    },

    // 下架
    reasonClick(item) {
      this.curlPost('/api/users/medium/apply', {
        id: item.id
      }).then(res => {
        console.log(res);

        if (res.data.code) {
          this.listFn()
          return this.$message({
            message: '申请成功待审核',
            type: 'success',
          })

        }
      })
    },
    // 暂停
    reason(item) {
      this.$confirm('是否暂停该媒体?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/medium/edit_status', {
          id: item.id,
          status: 1,
        }).then(res => {
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '暂停成功'
            });
            this.listFn()
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消暂停'
        });
      });

    },
    // 删除
    // deleteFn(item) {
    // 	this.$confirm('暂停后再启用需要重新进行审核！', '提示', {
    // 		confirmButtonText: '确定暂停',
    // 		cancelButtonText: '取消',
    // 		type: 'warning'
    // 	}).then(() => {
    // 		this.curlGet('/api/users/medium/del',{
    // 			id:item.id
    // 		}).then(res => {
    // 			console.log(res);
    // 			if (res.data.code) {
    // 				this.listFn()
    // 				this.$message({
    // 					type: 'success',
    // 					message: '删除成功!'
    // 				});
    // 			}
    // 		})

    // 	}).catch(() => {
    // 		this.$message({
    // 			type: 'info',
    // 			message: '已取消删除'
    // 		});
    // 	});
    // },

    // 申请上架
    UpClick(item) {
      this.$confirm('是否申请上架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/medium/apply', {
          id: item.id
        }).then(res => {
          console.log(res);
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '申请成功'
            });
            this.listFn()
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消暂停'
        });
      });

    },
    // 编辑
    upDateFn(item) {
      this.$store.state.AddRs = item.category_id
      this.$store.state.active = item.category_id
      this.$router.push({
        path: '/user/add_resource/WeMedia',
        query: {
          // id: this.$store.state.AddRsId
          id: item.id
        }
      })
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/pubTable'
</style>